.pin {
	width: 45px;
	height: 50px;
}

.mapboxgl-marker {
	width: 45px;
	height: 50px;
}

.mapboxgl-marker-anchor-bottom {
	width: 45px;
	height: 50px;
}
